import React, { useEffect, FC, useState, useCallback } from 'react';

import './App.css';

const App: FC = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [message, setMessage] = useState<string>();

	const update = useCallback(() => {
		const xhr = new XMLHttpRequest();
		xhr.onload = () => {
			setError(undefined);
			setMessage(xhr.responseText);
			setLoading(false);
		};
		xhr.onerror = () => {
			setError('Network Error');
			setLoading(false);
		};
		xhr.open('GET', 'server.php');
		xhr.send();
	}, [setLoading, setError, setMessage]);

	useEffect(() => {
		update();
		const interval = window.setInterval(update, 10000);
		return () => window.clearInterval(interval);
	}, [update]);

	return (
		<div className="App">
			<header className="App-header">
				{loading ? (
					<p>Loading...</p>
				) : error ? (
					<p>ERROR: {error}</p>
				) : (
					<p>
						Current Time: <strong>{message}</strong>
					</p>
				)}
			</header>
		</div>
	);
};

export default App;
